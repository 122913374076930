.p_blocks{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
 
.p_blocks_info{
    display: flex;
    flex-direction: column;
    padding: 10px 5px 10px 5px;
    border-radius: 30px;
    color: white;
    background: #ffffff;
    

}

.p_info_block{
    display: flex;
    justify-content: space-between;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 1vw;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
}

.p_item{
    color: rgb(0, 0, 0);
}


.p_input {
    font-size: 1.3rem;
    font-family: 'Kanit', sans-serif;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
    text-align: center;
}


.p_input:focus{
    outline: none ;
}

.d_p_input.address{
    width: 100%;
    display: grid;
    grid-template-columns: 7% 93%;
    text-align: left;
}


.d_p_input.treatment_history{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    text-align: left;
}

.d_p_input.chronic_Disease{
    width: 100%;
    display: grid;
    grid-template-columns: 16% 84%;
    text-align: left;
}



@media(max-width:992px) {
    .p_info_block{
        font-size: 1em;
    }
    .p_input{
        font-size: 1rem;
    }

    .d_p_input.treatment_history{
        display: unset;
        text-align: center;
    }
    .d_p_input.chronic_Disease{
        display: unset;
        text-align: center;
    }
    .d_p_input.address{
        display: unset;
        text-align: center;
    }
    
    
  }
  
@media(max-width:572px) {
    .p_info_block{
        font-size: 0.9em;
        flex-direction: column;
    }
    .p_input{
        font-size: 0.9em;
    }
}

.p_button {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: #2ba8fb;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .p_button:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }
  
  .p_button:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }