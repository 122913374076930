.sc_block{
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 10px;

}

.fc{
    all:unset;
}

.fc .fc-button-primary{
    background-color: rgb(52, 123, 216);
    border: unset;
}


.fc-icon{
    color: rgb(255, 255, 255);
}

.fc-day-today {
    background: #eeeeee  !important;
    border-color: none !important;
  
} 

@media(max-width:572px){
    .fc{
        font-size: 0.6rem;
    }
}