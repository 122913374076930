@import url('https://fonts.googleapis.com/css?family=Kanit&subset=thai,latin');

body {
  overflow-x:hidden;
  overflow-y: auto;
  background-color: #E0FAE6;
}


.App {
  text-align: center;
  font-family: 'Kanit', sans-serif;
}



.nav {
  background-color: #c95252;
  overflow: hidden;
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  z-index: 1000;
}

.navContainer {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  height: 8vh;
  z-index: 1000;
}

.navText{
  color: #f2f2f2;
  text-align: center;
  padding: 10px 12px;
  text-decoration: none;
  font-size: 19px;
  
}

.navText:hover{
  cursor: pointer;
}


.navText_right{
  color: #f2f2f2;
  text-align: center;
  padding: 10px 12px;
  text-decoration: none;
  font-size: 17px;
}

.navText_right:hover{
  cursor: pointer;
}
.navText_right.username{
  color: rgb(56, 54, 54)
}

.left-item{
  display: flex;
  align-items: center;
}

.right-item{
  display: flex;
  align-items: center;
}

.xx{
  margin: 20px;
  cursor: pointer;
  visibility: hidden;
}

.dropdown_menu{
  display: none;
  flex-direction: column ;
  position: absolute;
  right: 2rem;
  top:8.5vh;
  width: 300px;
  max-height: 0;
  background: rgb(56, 54, 54, 0.5);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
  transition: max-height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open{
  max-height: 500px;

}

.item{
  padding: 0.7rem;
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}

@media(max-width:992px) {
  .navText_right{
    display: none;
  }
  .navText{
    display: none;
  }
  .xx{
    visibility: visible;
  }
  .dropdown_menu{
    display: block;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media(max-width:572px) {
  .dropdown_menu{
    left :2rem;
    width: unset;
  }
}

.ant-picker-clear {
  display: none;
}

.background-container {
  position: relative;
}



.content {
  /* Other content styles */
}
