.cc_block {
    padding:10px;
    display: flex;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    
}

.cc_block_info{
    display: flex;
    flex-direction: column;
    border:  1px solid black;
    padding: 5vw;
    padding-bottom: 5vh;
    border-radius: 10px;
    box-shadow: 1px 2px;
    margin-top: 10vh;
    max-width: 80%;
    min-width: 30%;
    height: auto;
    font-size: max(1.3em,0.5em);
    align-items: center;
    background-color: white;
    
}

.cc_Field{
    font-size: max(1.3em,12px);
    width: 100%;
}



.cc_btn{
    width: 40%;
    margin-top: 2vw;
    font-size: max(1.3em,12px);
}

.cc_myButton {
    width: 40%;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size: max(1.3em,12px);
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    margin-top: 2vw;
}
.cc_myButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.cc_myButton:active {
	position:relative;
	top:1px;
}

.cc_label{
    width: 100%;
    text-align: left;
}