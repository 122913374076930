.blocks {
    padding:10px;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.blocks_info{
    display: flex;
    flex-direction: column;
    padding: 10vh;
    margin: 1px;
    border-radius: 10px;
    color: white;
    background: #4d4c4c;
}

div.a{
    display: flex;
    font-size: 20px;
    justify-content: space-around;
}




.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.popup-content {
background-color: #fff;
padding: 20px;
border-radius: 5px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.course{
    display: grid;
    grid-template-columns: 10% 22.5% 22.5% 22.5% 22.5%;
    width: 100%;
    background-color: white;
}

.course.real:hover{
    cursor: pointer;
}





.course_item{
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 1%;
    text-align: center;
    overflow: hidden;
    background-color: white;
}

.course_item.finished{
    background-color: rgb(170, 170, 170);
}

.course_item.finished:hover{
    cursor: pointer;;
}


.course_header{
    font-weight: bold;
}





.div_test{
    border: 1px solid rgba(253, 6, 6, 0.8);
}


.course_detail{
    max-height: 0;
    overflow: hidden;
    transition: all  cubic-bezier(0,1,0,1);
    grid-column-start: 1 ;
    grid-column-end: span 6;
    
    
}


.course_detail_show{
    
    height: auto;
    max-height: 999px;
    transition: all  cubic-bezier(1,0,1,0);
    grid-column-start: 2 ;
    grid-column-end: span 6;
    padding: 1%;
    
    
    
    
}

.course_detail_items{
    display: grid;
    grid-template-columns: 10% 27% 27% 27% 9%;
    
    
    
}



.course_detail_items.finished{
    display: grid;
    grid-template-columns: 10% 30% 30% 30% ;
    
}

.course_detail_test.fnished{
    background-color: #4d4c4c;
}




.course_times{
    display: grid;
    grid-template-columns: 10% 20% 20% 20% 10% ;
    
    
    
}

.course_times_item{
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    overflow: hidden;
    background-color: white;
}

.course_times_item.finished {
    background-color: rgb(170, 170, 170);
}

.course_span{
    grid-column-start: 1;
    grid-column-end: 6;
    
}

.course.finished{
    background-color: #666666;
}

.course.finished:hover{
    cursor: pointer;
}


.course_finish_button{
    font-size: 50%;
    padding: 0.3em 1em;
    margin-bottom: 1%;
    margin-top: 1%;
    border: transparent;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
    background: rgb(74, 206, 107);
    color: white;
    border-radius: 2px;
    margin-left: 2%;
}

.course_finish_button:not(:disabled):hover{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
}

.course_finish_button:not(:disabled):active {
transform: translate(0em, 0.2em);
}

.course_finish_button:disabled{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

.button_a{
font-size: 100%;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin-top: 10px;
border: transparent;
box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
background: rgb(211, 46, 46);
color: white;
border-radius: 2px;

}



.button_a:hover{
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
}

.button_a:active {
transform: translate(0em, 0.2em);
}




.button_b{
font-size: 100%;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin: 10px;
border: transparent;
box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
background: rgb(65, 211, 46);
color: white;
border-radius: 2px;
}

.button_b:disabled{
font-size: 100%;
padding-left: 1rem;
padding-right: 1rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
margin: 10px;
border: transparent;
box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
background: rgb(135, 138, 135);
color: white;
border-radius: 2px;
}
    
.button_b:hover{
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
}

.button_b:active {
transform: translate(0em, 0.2em);
}

.hiddem{
    background-color: grey;
}


.CustomTabsList {
    background-color: #0D5463;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    width: 30rem;
    
  }
  
  .CustomTab {
    font-family: 'IBM Plex Sans', sans-serif;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    line-height: 1.5;
    padding: 4px 8px;
    margin: 6px;
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    
  }
  
  .CustomTab:hover {
    background-color: #1F94AD;
  }
  
  .CustomTab:focus {
    color: #fff;
    outline: 3px solid #99D8E5;
  }
  
  .CustomTab.Mui-selected {
    background-color: #fff;
    color: #094855;
  }
  
  .CustomTab.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .CustomTabPanel {
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
  }
  
  .myButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    margin-top: 10px;
    margin-bottom: 20px
}
.myButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.myButton:active {
	position:relative;
	top:1px;
}


@media(max-width:992px) {
    div.a{
        display: unset;
    }
  }

:root{
    --fc-today-bg-color: #ccc
}
