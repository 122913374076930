.block_home {
    padding:10px;
    display: flex;
	margin-top: 10vh;
	align-items: center;
    height: 90vh;
    flex-direction: column;
}

.block_item{
    padding: 1vh;
    border-radius: 2px;
    margin: 20px;
	width: 30%;
	min-width: 300px;
	
}

.block_item_list
{
	display: flex;;
	flex-direction: row;
	padding: 1vh;
	border-radius:6px;
	background:linear-gradient(to bottom, #759cd6 5%, #4780d6 100%);
	color: white;
	justify-content: space-between;
	font-family:Arial;
	font-size:10px;
	
}

.block_item_list:hover {
	background:linear-gradient(to bottom, #4780d6 5%, #759cd6 100%);
	background-color:#ad2323;
}
.block_item_list:active {
	position:relative;
	top:1px;
}


.myButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.myButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.myButton:active {
	position:relative;
	top:1px;
}

.mainimg{
	width: 40vh;
	min-width: 200px;
	height: auto;
}

