.hover:hover{
    cursor: pointer;
}
.ant-table-cell {
    border: 1px solid #205e47 !important; /* Set the border style for table cells */
  }

  /* Add a border to the last row of the table */
.ant-table-row:last-child .ant-table-cell {
    border-bottom: 1px solid #357960;
}

/* Collapse table borders */
.ant-table {
    border-collapse: collapse;
}

/* Custom CSS for changing the header color */



.ant-tabs-nav .ant-tabs-tab {
    background-color: #ffffff; /* Change 'your-color' to the desired color */
    color: #fff; /* Change text color to make it readable on the background */
    border: none; /* Remove border if not needed */
    }
    
    /* Custom CSS to change the active tab color */
    .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #ffffff; /* Change 'your-active-color' to the desired active tab color */
    color: #fff; /* Change text color for the active tab */
    }

.ant-descriptions-view
{
    background-color: white;
}

.ant-descriptions-title
{
   
}

.ant-table-tbody >tr >td {
    border-bottom: 1px solid black !important;
    border-left: 1px solid black !important;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-card .ant-card-body {
    /* Your custom styles here */
    padding-bottom: 0;
    /* Add any other styles you want to apply */
  }

.ant-form-item-label > label.ant-form-item-required:before {
    content: unset !important;
}